import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import {gql} from "@apollo/client";
import {
  DrugTiersOutput, Maybe,
  PageablePdpPlanOutput,
  PdpPlan,
  QueryPdpPlansArgs
} from '@coverright/data-access/types/medicare';

export function usePDPPlans(options?: LazyQueryHookOptions<IPlansOutput, QueryPdpPlansArgs>) {
  return useLazyQuery<IPlansOutput, QueryPdpPlansArgs>(gql(getPlans), {
    fetchPolicy: "no-cache",
    errorPolicy: 'all',
    ...options
  });
}

interface OverridedPageablePdpPlanOutput extends PageablePdpPlanOutput{
  data: PdpPlanWithTiers[],
}

interface IPlansOutput {
  PdpPlans: OverridedPageablePdpPlanOutput
}

export interface PdpPlanWithTiers extends PdpPlan {
  drugsTiers30Preferred: Maybe<DrugTiersOutput>,
  drugsTiers30Standard: Maybe<DrugTiersOutput>,
  drugsTiers90Preferred: Maybe<DrugTiersOutput>,
  drugsTiers90Standard: Maybe<DrugTiersOutput>,
}

export const pdpPlanFields = `
        SNPType
        bidId
        drugsCost
        monthlyCost
        contractNumber
        quoteDrugsTiers(supplyDuration: ONE_MONTH) {
          deliveryType
          pharmacyName
          initialCoverageDrugTiers {
            coverage
            tierName
            tier
          }
        }
        drugPrices {
          catastrophicPrice
          coverageGapPrice
          initialCoveragePrice
          name
          price
          unitPrice
          rxcui
          tier
          quoteQuantity
          isCoveredByPlan
          packageDescription
          quantityLimit
          isAggregatedPrice
          isGeneric
          isInsulinDrug
        }
        drugsCoverage {
          rxcui
          coverage
          name
        }
        drugCostMonthlyBreakdown {
          totalClientCost
          costs {
            name
            month
            cost
            rxcui
            stage
          }
          coverageEntry {
            stage
            month
          }
          monthlyCosts {
            amount
            amountString
            month
            stage
          }
        }
        drugDetails {
          catastrophicCopayBranded
          catastrophicCopayGeneric
          catastrophicCoverageDescription
          catastrophicThreshold
          coverageGapDescription
          initialCoverageDescription
          initialCoverageLimit
          mrxAltDedAmount
          mrxAltDedAmountShort
          mrxAltNoDedTier
        }
        drugsTiers30Preferred: drugTiers(supplyDuration: ONE_MONTH, deliveryMethod: PREFERRED_RETAIL) {
          initialCoverageDrugTiers {
            coverage
            tierName
            tier
          }
          coverageGapDrugTiers {
            coverage
            tierName
            tier
          }
        }
        drugsTiers30Standard: drugTiers(supplyDuration: ONE_MONTH, deliveryMethod: STANDARD_RETAIL) {
          initialCoverageDrugTiers {
            coverage
            tierName
            tier
          }
          coverageGapDrugTiers {
            coverage
            tierName
            tier
          }
        }
        drugsTiers90Preferred: drugTiers(supplyDuration: THREE_MONTHS, deliveryMethod: PREFERRED_MAIL) {
          initialCoverageDrugTiers {
            coverage
            tierName
            tier
          }
          coverageGapDrugTiers {
            coverage
            tierName
            tier
          }
        }
        drugsTiers90Standard: drugTiers(supplyDuration: THREE_MONTHS, deliveryMethod: STANDARD_MAIL) {
          initialCoverageDrugTiers {
            coverage
            tierName
            tier
          }
          coverageGapDrugTiers {
            coverage
            tierName
            tier
          }
        }
        inNetworkDeductableAmount
        insulinSavings
        maxEnrollmentAmount
        missingFormularyInAep
        monthlyPremium
        orgName
        outOfPocketAmount
        parentOrgName
        partBPremium
        pbpADsnpZeroDollar
        pbpASnpStateCvgYn
        planName
        planYear
        planType
        planTypeCode

`

const getPlans = `
query($filter: PdpPlansFilterInput!, $page: PageInput!, $fullYear: Boolean, $sort: [PdpPlansSortInput!]!, $clientId: UUID) {
    PdpPlans(filter: $filter, page: $page, sort: $sort, fullYear: $fullYear, clientId: $clientId) {
      data {
        ${pdpPlanFields}
      }
      totalElements
      hasNext
    }
}
`;
