import { getStoredUser, setStoredUser } from '@coverright/utils';
import moment from 'moment';
import { Gender } from '@coverright/data-access/types/medigap';
import { client } from '@coverright/data-access/apollo-clients';
import { gql } from '@apollo/client';

export async function handleURLParams(zipRoute?: string) {
  const urlParams = new URLSearchParams(window.location.search);

  urlParams.has('quote') && addParam('quoteId', urlParams.get('quote'));
  urlParams.has('medigapQuote') &&
    addParam('medigapQuoteId', urlParams.get('medigapQuote'));
  urlParams.has('source') && addParam('profileSource', urlParams.get('source'));

  const firstName = urlParams.get('firstName') || urlParams.get('first_name');
  firstName && addParam('firstName', firstName);

  const lastName = urlParams.get('lastName') || urlParams.get('last_name');
  lastName && addParam('lastName', lastName);

  if (urlParams.has('dob') && moment(urlParams.get('dob')).isValid()) {
    addParam('birthDate', moment(urlParams.get('dob')).format('YYYY-MM-DD'));
  }

  urlParams.has('email') && addParam('email', urlParams.get('email'));
  urlParams.has('phone') && addParam('phoneNumber', urlParams.get('phone'));
  urlParams.has('gender') &&
    addParam(
      'lastName',
      urlParams.get('gender') === 'female' ? Gender.F : Gender.M
    );
  urlParams.has('tobacco') && addParam('tobacco', urlParams.get('tobacco'));

  if (urlParams.has('target')) {
    sessionStorage.setItem('target', urlParams.get('target') as string);
  }

  if (urlParams.has('utm_source')) {
    sessionStorage.setItem('utm_source', urlParams.get('utm_source') as string);
  }

  if (urlParams.has('utm_term') && urlParams.get('utm_term') !== 'general') {
    sessionStorage.setItem('skipConversion', 'true');
  }

  if (
    urlParams.has('zip') &&
    (!getStoredUser()?.zip ||
      !getStoredUser()?.countyName ||
      getStoredUser()?.zip !== urlParams.get('zip'))
  ) {
    const zip = urlParams.get('zip')!;

    if (!urlParams.has('county')) {
      const res = await client.query({
        query: gql(query),
        variables: { zip },
      });

      if (res.data?.plans_zip_county_fips.length === 1) {
        setStoredUser({
          ...getStoredUser(),
          countyName: res.data?.plans_zip_county_fips[0].countyname,
          state: res.data?.plans_zip_county_fips[0].state,
          zip,
        });
      } else {
        console.info('There is more then one county in this zip.');
        setStoredUser({
          ...getStoredUser(),
          zip,
        });
        if (zipRoute) {
          document.location.href = zipRoute;
        }
      }
    } else {
      setStoredUser({
        ...getStoredUser(),
        countyName: urlParams.get('county')!,
        zip,
        state: urlParams.get('state') || undefined,
      });
    }
  }
}

const addParam = (name: string, value: any) => {
  setStoredUser({
    ...getStoredUser(),
    [name]: value,
  });
};

const query = `
query ($zip: String!) {
    plans_zip_county_fips(where: {zip: {_eq: $zip}}) {
      city
      state
      countyname
    }
  }
`;
