import {LazyQueryHookOptions, useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import { MedigapPlanOutput, QueryMedigapPlanArgs } from '@coverright/data-access/types/medigap';
import * as React from 'react';

export function useMedigapPlan(options?: LazyQueryHookOptions<IPlanOutput, QueryMedigapPlanArgs>) {
  return useLazyQuery<IPlanOutput, QueryMedigapPlanArgs>(gql(getPlan), {
    ...options,
  });
}

interface IPlanOutput{
  medigapPlan: MedigapPlanOutput
}



export function useMedigapPlansByBid( ids?: string[], onComplete?: (data: any) => void) {
  const [plans, setPlans] = React.useState<MedigapPlanOutput[]>([]);
  const [getPlans, {loading, called}] = useLazyQuery<{[name: string]: MedigapPlanOutput}>(gql(getPlansQuery(ids)), {
    onCompleted: data => {
      if (data) {
        setPlans(Object.values(data).filter(p => !!p))
      }
      if (onComplete) {
        onComplete(data);
      }
    },
    errorPolicy: "ignore",
    fetchPolicy: "no-cache"
  });

  React.useEffect(() => {
    if (ids && ids.length) {
      getPlans();
    }
  }, [ids])

  return {plans, loading, called};

}

const getPlansQuery = (plans?: string[]) => {
  let query;
  if (plans?.length) {
    query = Object.keys(plans).map(parseFloat).map((i: number) => `plan${i}: medigapPlan(key: "${plans[i]}") {
      ${planFields}
    }`)
  } else {
    query = `getMyQuoteId`
  }
  return `{${query}}`;
};

const planFields = `
    amBestRating
    householdDiscount
    key
    monthlyPremium
    orgName
    parentOrgName
    title
    subTitle
    naic
    spRating
    ratingClass
    planName
    effectiveDate
    showAdditionalBenefitsDescription
`

const getPlan = `
query($key: String!) {
  medigapPlan(key: $key) {
    ${planFields}
  }
}
`;

