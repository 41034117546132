import React, { PropsWithChildren } from 'react';
import { client } from '@coverright/data-access/apollo-clients';
import { ThemeProvider } from '@mui/material';
import { AppTheme } from '@coverright/ui/themes';
import { KeycloakProvider } from '@coverright/shared/keycloak';
import {
  AdminContextProvider, AgentContextProvider,
  AppContextProvider, MedigapQuoteProvider,
  QuoteProvider,
  UserTestContextProvider
} from '@coverright/shared/contexts';
import { getClientId, getQuoteId } from '@coverright/utils';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'use-http';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import { environment } from '../environments/environment';

export function Providers({ children }: PropsWithChildren) {
  return <ApolloProvider client={client}>
    <Provider url={environment.userFlowRestUrl}>
      <ThemeProvider theme={AppTheme}>
        <SnackbarProvider preventDuplicate classes={{
          variantSuccess: 'success-snack'
        }} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }} maxSnack={3}>
          <KeycloakProvider lateInit allowAdmin>
            <AppContextProvider>
              <BrowserRouter>
                <UserTestContextProvider>
                  <AdminContextProvider clientId={getClientId()}>
                    <QuoteProvider quoteId={getQuoteId()}>
                      <MedigapQuoteProvider>
                        <AgentContextProvider>
                          {children}
                        </AgentContextProvider>
                      </MedigapQuoteProvider>
                    </QuoteProvider>
                  </AdminContextProvider>
                </UserTestContextProvider>
              </BrowserRouter>
            </AppContextProvider>
          </KeycloakProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  </ApolloProvider>;
}
