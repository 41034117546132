import { Box, Button, Fab, IconButton, Typography } from '@mui/material';
import React, { useRef } from 'react';
import {
  AdminContext,
  CompareOfferContext,
  ProfileContext,
  withCompareOfferContext
} from '@coverright/shared/contexts';
import { ComparablePlan, ComparablePlanType, PlanYear } from '@coverright/data-access/types/medicare';
import { PdpPlanWithTiers, usePdpPlansByBid } from '@coverright/data-access/medicare';
import { getCompanyLogo, getNumbersFromString, toCurrency } from '@coverright/utils';
import { SxProps } from '@mui/material';
import { KeycloakContext } from '@coverright/shared/keycloak';
import { colors } from '@coverright/ui/themes';
import * as _ from 'lodash';
import { DrugCoverageList } from '@coverright/ui/marketplaces';
import { CRPreloader } from '@coverright/ui/shared';
import { environment } from '../../../environments/environment';
import { Link } from 'react-router-dom';
import { PageRoutes } from '../../config/page-routes';
import html2canvas from 'html2canvas';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const styles: SxProps = {
  position: 'relative',
  overflowY: {xs: 'auto', md: 'unset'},
  '& table': {
    background: 'white',
    borderSpacing: '5px',
    '& ul': {
      marginBlockStart: '3px',
      marginBlockEnd: '10px',
      paddingInlineStart: '16px',
    },
    '& td': {
      fontSize: 13,
      color: colors.text.primary,
      whiteSpace: 'break-spaces',
    },
    '& thead td': {
      '&:not(:first-child)': {
        fontSize: 14,
        background: '#0f738b',
      }
    },
    '& tbody td': {
      padding: '1px 5px',
      '&:first-child': {
        position: 'sticky',
        left: 0,
        zIndex: 1,
        background: 'white',
        '&:not(.bg-white)': {
          background: '#c8e8eb',
          fontWeight: '700',
        }
      },
      '&:not(:first-child)': {
        '&.selected': {
          background: '#f0f8ff',
        },
        verticalAlign: 'top',
        '&:not(.borderless)': {
          borderBottom: '1px solid #d9d9d9',
        },
        minWidth: 250,
      },
    }
  }
}

interface ComparePlan {
  zip: string,
  bidId: string,
  countyName: string,
  planYear?: PlanYear,
  pdpPrimary?: boolean
}

function PdpCompareOffer() {
  const adminContext = React.useContext(AdminContext);
  const {plans, clientId, changePdpPrimary, togglePlan} = React.useContext(CompareOfferContext);
  const {keycloak, initialized} = React.useContext(KeycloakContext);
  const {firstName, lastName, countyName, state} = React.useContext(ProfileContext);
  const [pdpComparePlans, setPdpComparePlans] = React.useState<ComparePlan[]>();
  const pdpPlans = usePdpPlansByBid(pdpComparePlans, clientId);
  const ref = useRef<any>(null);
  const [screenshotProcess, setScreenshotProcess] = React.useState(false);

  React.useEffect(() => {
    if (initialized && !keycloak.authenticated) {
      keycloak.login()
    }
  }, [initialized])

  React.useEffect(() => {
    setPdpComparePlans(plans.filter(p => p.type === ComparablePlanType.Pdp).map(plan => ({bidId: plan.bidId, zip: plan.zip, countyName: plan.county, planYear: plan.planYear || undefined, pdpPrimary: !!plan.pdpPrimary})))
  }, [plans])

  const loading = React.useMemo(() => {
    return !pdpPlans.length;
  }, [pdpPlans])

  const getCompPlan = React.useCallback((bidId: string): ComparePlan | undefined => {
    return pdpComparePlans?.find(p => p.bidId === bidId);
  }, [pdpComparePlans])

  React.useEffect(() => {
    if (screenshotProcess && ref.current) {
      html2canvas(ref.current, {useCORS: true,}).then(canvas => {
        canvas.toBlob((data: any) => {
          const dataURL = URL.createObjectURL(data)
          const link = document.createElement("a");
          link.download = [adminContext?.clientView.firstName, adminContext?.clientView.lastName].join('_') + '_compare_offer.png';
          link.href = dataURL;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setScreenshotProcess(false)
        });
      })
    }
  }, [screenshotProcess, ref, adminContext])

  if (loading) {
    return <CRPreloader />
  }

  return <Box sx={styles}>
    {(!!adminContext && !!plans.filter(p => p.type !== ComparablePlanType.Pdp).length) && <Box sx={{top: -45, position: 'absolute'}}>
      <div className={'mb-10'}>
        <Link to={PageRoutes.compareOffer + (adminContext?.clientId ? `?clientId=${adminContext.clientId}` : '')} className={'fs-13 underline pointer'}>Go to compare offer</Link>
      </div>
      <Button variant={'outlined'} size={'small'} color={'primary'} onClick={() => setScreenshotProcess(true)}>
        Download
      </Button>
    </Box>}
    <Box display={'flex'} mb={3} sx={{
      position: 'sticky',
      left: 0,
      zIndex: 1,
    }}>
      {firstName && <Typography className={'medium fs-24'}>{firstName + ' ' + lastName}, here is a comparison of your PDP options</Typography>}
      {countyName && <Typography sx={{color: colors.custom.green.variant2}} className={'ml-20 bold fs-24'}>{countyName}, {state}</Typography>}
    </Box>
    <table ref={ref}>
      <thead>
        <tr>
          <td><Box component={'img'} sx={{}} src={'/assets/img/Logo.svg'} width={121} height={22} /></td>
          {!!pdpPlans.length && <td className={'medigap bold white text-align-centered'} colSpan={pdpPlans.length}>Prescription Drug Plans </td>}
        </tr>
      </thead>
      <tbody>
        <TableRow
          title={'Monthly Premium'}
          plans={pdpPlans}
          getCellContent={plan => <td className={`header bold fs-18 ${getCompPlan(plan.bidId)?.pdpPrimary && 'selected'}`}>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <span>{plan.monthlyPremium}</span>
              {(!screenshotProcess && !!adminContext) && <IconButton onClick={() => togglePlan(plans.find(p => p.bidId === plan.bidId) as ComparablePlan)}><img src={'/assets/img/close.svg'} /></IconButton>}
            </Box>
            <Box component={'a'} sx={{display: 'block'}} href={`${environment.pdpUrl}plans/${getCompPlan(plan.bidId)?.zip}/${getCompPlan(plan.bidId)?.countyName}` + (adminContext?.clientId ? `?clientId=${adminContext.clientId}` : '') + `#${plan.bidId}`}><b>{plan.planName}</b></Box>
            <Logo url={getCompanyLogo(plan.parentOrgName, plan.orgName)}/>
          </td>}
        />
        <TableRow
          title={'Drug Coverage'}
          plans={pdpPlans}
          getCellContent={plan => <td key={plan.bidId} className={`${getCompPlan(plan.bidId)?.pdpPrimary && 'selected'}`}>
            <DrugCoverageList length={100} hideTitle coverage={plan.drugsCoverage}/>
          </td>}
        />
        <TableRow
          title={'Deductible'}
          plans={pdpPlans}
          getCellContent={plan => <td key={plan.bidId} className={`${getCompPlan(plan.bidId)?.pdpPrimary && 'selected'}`}>
              {([plan?.drugDetails?.mrxAltDedAmount || '', plan?.drugDetails?.mrxAltNoDedTier || ''].join('\n'))}
          </td>}
        />
        <TableRow
          title={'Monthly drug costs (Estimates)'}
          subtitle={'Cost based on filling at a Preferred Retail Pharmacy'}
          plans={pdpPlans}
          getCellContent={plan => <td key={plan.bidId} className={`borderless ${getCompPlan(plan.bidId)?.pdpPrimary && 'selected'}`}><GetDrugCost plan={plan} /></td>}
        />
        <tr><td className={'bg-white h-10'} colSpan={1 + pdpPlans.length}/></tr>
        <TableRow
          title={'Total Estimated Premium'}
          subtitle={'+ Drug Monthly Cost'}
          plans={pdpPlans}
          getCellContent={plan => <td key={plan.bidId} className={'borderless fs-18'} style={{background: '#dae2f3'}}>
            <b>{toCurrency(getNumbersFromString(plan.monthlyPremium || '0')[0] + (getNumbersFromString(plan?.monthlyPremium || '0')[0]), 2, 2)}</b><br />
            <i className={'fs-11 no-wrap'}>+ average monthly drug costs ({getDrugCostDiapason(GetDrugCostArray(plan)) || 'N/A'})</i>
          </td>}
        />
        {!screenshotProcess && <>
          <tr><td className={'bg-white h-10'} colSpan={1 + pdpPlans.length}/></tr>
          <TableRow
            title={''}
            plans={pdpPlans}
            getCellContent={plan => <td key={plan.bidId} className={'borderless'}>
              {!getCompPlan(plan.bidId)?.pdpPrimary && <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Button onClick={() => changePdpPrimary(plan.bidId)} variant={'contained'} color={'primary'}>
                  Select plan
                </Button>
              </Box>}
              {!!getCompPlan(plan.bidId)?.pdpPrimary && <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Button variant={'rounded'}  size={'large'} className={'w-135 fs-16 pv-12 cursor-default'} sx={{
                  pointerEvents: 'none',
                  background: '#0A2E86',
                  '&:hover': {
                    background: '#0A2E86',
                  },
                  '&:active': {
                    background: '#0A2E86',
                  },
                }}>
                  Primary plan
                </Button>
              </Box>}
            </td>}
          />
        </>}
      </tbody>
    </table>
  </Box>
}

export default withCompareOfferContext(PdpCompareOffer)

const Logo = ({url}: {url: string}) => <div><Box sx={{height: 45,
  width: 150,
  minWidth: 150,
  marginTop: 0,
  mt: 1,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'left'}} style={{backgroundImage: `url("${url}")`}}  /></div>

const getDrugCostDiapason = (costs: Array<Array<string>>) => {
  const min = _.min(costs.map(v => getNumbersFromString(v[1])[0]))
  const max = _.max(costs.map(v => getNumbersFromString(v[1])[0]))
  if (isNaN(min as number)) {
    return 'N/A'
  }
  return min === max || isNaN(max as number) ? toCurrency(min as number) : toCurrency(min as number) + ' - ' + toCurrency(max as number);
}

const GetDrugCost= ({plan}: {plan: any}) => {
  if (!plan?.drugCostMonthlyBreakdown?.monthlyCosts?.length) {
    return <div>N/A</div>
  } else {
    return <>
      Preferred Retail:
      <ul>
        {GetDrugCostArray(plan).map(v => <li key={v[0]}><b>Month {v[0]}:</b> ~{v[1]}</li>)}
      </ul>
    </>
  }
}

const GetDrugCostArray = (plan: any) => {
  if (!plan?.drugCostMonthlyBreakdown?.monthlyCosts?.length) {
    return []
  } else {
    const output = _.map(plan.drugCostMonthlyBreakdown?.monthlyCosts, 'amount');
    let lastMonth = 0;
    return [..._.uniq(output).map((v, i) => {
              if (i === _.uniq(output).length - 1) {
                return [`${lastMonth + 1}+`, toCurrency(v, 0, 0)]
              } else {
                const newLastMonth = lastMonth + output.filter(i => i === v).length;
                const duration = newLastMonth - lastMonth;
                let str;
                if (duration > 1) {
                  str = (lastMonth + 1) + '-' + newLastMonth;
                } else {
                  str = newLastMonth;
                }
                lastMonth = newLastMonth;
                return [`${str}`, toCurrency(v, 0, 0)]
              }
            })]

  }
}


interface TableRowProps {
  plans: PdpPlanWithTiers[],
  getContent?: (plans: PdpPlanWithTiers[]) => JSX.Element,
  getCellContent?: (plan: PdpPlanWithTiers) => JSX.Element,
  title: string,
  subtitle?: string,
}

const TableRow = (props: TableRowProps) => {
  return <tr>
    <td>
      <Typography sx={{whiteSpace: 'break-spaces'}} className={'bold lh-14'}>{props.title} <i className={'fs-12 regular'}><br />{props.subtitle}</i></Typography>
    </td>
    {!!props.plans.length && (props.getCellContent ? props.plans.map(props.getCellContent) : props.getContent && props.getContent(props.plans))}
  </tr>
}
