import { LazyQueryHookOptions, QueryHookOptions, useLazyQuery } from '@apollo/client';
import {gql} from "@apollo/client";
import { PlanYear, QueryPdpPlanArgs } from '@coverright/data-access/types/medicare';
import { pdpPlanFields, PdpPlanWithTiers } from '../use-pdp-plans/use-pdp-plans';
import * as React from 'react';

export function usePdpPlan(options?: LazyQueryHookOptions<{PdpPlan: PdpPlanWithTiers}, QueryPdpPlanArgs>) {
  return useLazyQuery<{PdpPlan: PdpPlanWithTiers}, QueryPdpPlanArgs>(
    gql(query),
    {
      ...options,
      fetchPolicy: 'no-cache'
    }
  );
}


export function usePdpPlansByBid( input?: IPlanInput[], clientId?: string) {
  const [plans, setPlans] = React.useState<PdpPlanWithTiers[]>([]);
  const [getPlans] = useLazyQuery<{[name: string]: PdpPlanWithTiers}>(gql(getPlansQuery(input, clientId)), {
    onCompleted: data => {
      if (data) {
        setPlans(Object.values(data).filter(p => !!p))
      }
    },
    errorPolicy: "ignore",
    fetchPolicy: "no-cache"
  });

  React.useEffect(() => {
    if (input && input.length) {
      getPlans();
    }
  }, [input])

  return plans;
}

const getPlansQuery = (plans?: IPlanInput[], clientId?: string) => {
  let query;
  if (plans?.length) {
    query = plans.map((plan, i) => `plan${i}: PdpPlan(${clientId ? 'clientId: "' + clientId + '", ' : ''}bidId: "${plan.bidId}", zip: "${plan.zip}", countyName: "${plan.countyName}"${plan.planYear ? ', planYear: '+plan.planYear : ''}) {
      ${pdpPlanFields}
    }`)
  } else {
    query = `getMyQuoteId`
  }
  return `{${query}}`;
};

interface IPlanInput {
  zip: string,
  bidId: string,
  countyName: string,
  planYear?: PlanYear
}

const query = `query ($bidId: String!, $clientId: UUID, $countyName: String!, $zip: String!, $fullYear: Boolean, $planYear: PlanYear) {
  PdpPlan(bidId: $bidId, clientId: $clientId, countyName: $countyName, zip: $zip , fullYear: $fullYear, planYear: $planYear) {
    ${pdpPlanFields}
  }
}`
