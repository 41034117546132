import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './app/app';
import { initTrackEvent } from '@coverright/shared/analytics';
import { initKeycloak } from '@coverright/shared/keycloak';
import { Providers } from './app/providers';
import { handleURLParams } from '@coverright/features';

const urlParams = new URLSearchParams(window.location.search);

function validURL(myURL: string) {
  return !myURL.includes('{') && !myURL.includes('}');
}

if (!validURL(document.location.href)) {
  const keys = Array.from(urlParams.entries())
    .filter((p) => !!p[1] && !p[1].includes('{') && !p[1].includes('}'))
    .map((p) => p[0]);

  if (keys.length) {
    const newParams = new URLSearchParams();
    keys.forEach((key) => newParams.set(key, urlParams.get(key) as string));
    document.location.href =
      document.location.origin +
      document.location.pathname +
      '?' +
      newParams.toString();
  } else {
    document.location.href =
      document.location.origin + document.location.pathname;
  }
}

const root = createRoot(document.getElementById('root') as HTMLElement);

initApp().then(() => {
  root.render(
    <Providers>
      <App />
    </Providers>
  );
});

async function initApp() {
  await initKeycloak();
  let flow = 'integrated-flow';
  if (urlParams.has('product')) {
    flow = urlParams.get('product') as string;
  }
  initTrackEvent(flow);
  await handleURLParams();
}
